import * as React from "react";
import {
  Body,
  Column,
  Container,
  Head,
  Heading,
  Hr,
  Html,
  Img,
  Link,
  Row,
  Section,
  Tailwind,
  Text,
} from "@react-email/components";
import { Reservation } from "../typings/reservation";
import moment from "moment/moment";
import { Customer } from "../typings/customer";
import { Room } from "../typings/rooms";

const Box = (props: {
  id: string;
  day: string;
  date: string;
  icon: any;
  time: string;
}) => {
  return (
    <Container className={""}>
      <Section>
        <Row className={"ml-6"}>
          <Column align={"left"}>{props.icon}</Column>
          <Column align={"left"}>{props.id}</Column>
        </Row>
      </Section>

      <Hr className={"border-solid border-[#eaeaea]"} />
      <Container className={"p-6"}>
        <Text className={"font-extrabold text-lg p-0 m-0"}>{props.day}</Text>
        <Text className={"text-lg p-0 m-0"}>{props.date}</Text>
        <Text className={"font-thin text-sm p-0 m-0"}>{props.time}</Text>
      </Container>
    </Container>
  );
};

const Support = ({ href, title }: { href: string; title: string }) => {
  return (
    <Link href={href} className={"text-gray-800"}>
      <Text
        className={
          "text-center text-xl p-3 mb-3 rounded-xl border border-solid border-[#eaeaea]"
        }
      >
        {title}
      </Text>
    </Link>
  );
};

const Price = ({ amount, title }: { amount: string; title: string }) => {
  return (
    <Container
      className={
        "items-center justify-center flex flex-col rounded border py-3 mx-auto"
      }
    >
      <Text className={"font-thin p-0 m-0 mx-auto"}>{title}</Text>
      <Text className={"font-extrabold text-xl p-0 m-0 mx-auto"}>{amount}</Text>
    </Container>
  );
};

const SectionTitle = ({ title }: { title: string }) => {
  return (
    <Heading className="font-extrabold text-2xl my-3 text-gray-90">
      {title}
    </Heading>
  );
};

const SectionSubTitle = ({ title }: { title: string }) => {
  return <Text className={"font-thin text-xl"}>{title}</Text>;
};

const SectionItem = (props: any) => {
  return <Container className={"my-6"}>{props.children}</Container>;
};

const Logo = () => {
  return (
    <Text className={"font-extrabold text-pink-500"}>IFEMIDE ESTATES</Text>
  );
};

const FAQS = [
  {
    title: "How can I get directions to the property?",
    link: "https://maps.app.goo.gl/VbppFuiQWzZyjMWVA",
  },
  {
    title: "Can I modify my reservation?",
    link: "https://drive.google.com/file/d/1d1Ypa6MOwsyR4CBPEBF4tJe0j0QnsPti/preview",
  },
  {
    title: "Can I check in earlier?",
    link: "https://drive.google.com/file/d/1d1Ypa6MOwsyR4CBPEBF4tJe0j0QnsPti/preview",
  },
  {
    title: "Do you offer housekeeping services during the stay?",
    link: "https://drive.google.com/file/d/1d1Ypa6MOwsyR4CBPEBF4tJe0j0QnsPti/preview",
  },
  {
    title: "What is the cancellation policy?",
    link: "https://drive.google.com/file/d/1d1Ypa6MOwsyR4CBPEBF4tJe0j0QnsPti/preview",
  },
];

interface Props {
  reservation: Reservation;
  customer: Customer;
  room: Room;
}

function formatNumber(num: number) {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 10,
    notation: "compact",
    compactDisplay: "short",
  }).format(num);
}

export function ConfirmedReservations({ reservation, customer, room }: Props) {
  const roomDetails = room.description!;
  const reservationId = `${reservation.reservationId}`;

  const customerTitle = customer!.salutation;
  const customerFirstName = customer!.firstName;
  const customerLastName = customer!.lastName;

  const guests = `${reservation.numberOfGuests} Guests`;

  const checkInDay = moment(reservation.checkInDate).format("dddd");
  const checkInDate = moment(reservation.checkInDate)!.format("MMMM D YYYY");
  const checkInTime = moment(reservation.checkInTime, "HH=mm").format(
    "hh:mm A",
  );

  const checkOutDay = moment(reservation.checkOutDate).format("dddd");
  const checkOutDate = moment(reservation.checkOutDate)!.format("MMMM D YYYY");
  const checkOutTime = moment(reservation.checkOutTime, "HH=mm").format(
    "hh:mm A",
  );

  const paid = formatNumber(reservation.payment.paid);
  const due =
    reservation.payment.due === 0 ? "-" : formatNumber(reservation.payment.due);
  const total = formatNumber(reservation.payment.total);

  return (
    <Html lang="en">
      <Head />
      <Tailwind>
        <Body className="bg-white my-auto mx-auto font-sans px-2">
          <Container className="border border-solid border-[#eaeaea] rounded-xl my-[40px] mx-auto p-[20px] max-w-[465px]">
            <SectionItem className={"my-6"}>
              <Logo />
            </SectionItem>

            <SectionItem>
              <Img
                alt={"Ifemide Host Thumbnail Photo"}
                height={50}
                width={50}
                className={
                  "filter brightness-200 contrast-500 contain-content rounded-full m-0 p-0"
                }
                src={
                  "https://a0.muscache.com/im/pictures/user/User-518640099/original/bed6062b-c13d-43b2-b60f-ad57aed9825d.jpeg?aki_policy=profile_large"
                }
              ></Img>
              <Text className="font-thin m-0 text-xs">Rita Esosa, Host</Text>
            </SectionItem>

            <Heading className={"text-3xl font-extrabold text-gray-700"}>
              {`Reservation Confirmation: Ifemide Estates, Alagbaka Akure – ${customerTitle} ${customerFirstName} ${customerLastName} [ID: #${reservationId}]`}
            </Heading>

            <Container className={"overflow-hidden"}>
              <Img
                height="160"
                style={{
                  width: "100%",
                  objectFit: "cover",
                }}
                className={"rounded-xl cover"}
                src={
                  "https://lh3.googleusercontent.com/pw/AP1GczOJnVN6wRy0F2T0CmQgrAu7dc2spMasxVNnqRlOrcN8HjMvDJ27fFp-uFanTp1ibT8RrgCVUPBfu4Q61KgPenMDYqzAuukLf8Zlh0RoJqCkURnEjw=w2400"
                }
                alt={"Ifemide Solitiare Building Photo"}
              />
            </Container>

            <SectionItem>
              <SectionTitle title={"Reservation details"} />
              <SectionSubTitle title={roomDetails} />
            </SectionItem>

            <SectionItem>
              <Container className={"mb-3"}>
                <SectionTitle title={"Guests"} />
                <SectionSubTitle title={guests} />
              </Container>
              <Section>
                <Row
                  className={"border rounded-xl border-solid border-[#eaeaea]"}
                >
                  <Column className={"mr-3"}>
                    <Box
                      icon={<Text>&darr;</Text>}
                      id={"Check In"}
                      day={checkInDay}
                      date={checkInDate}
                      time={checkInTime}
                    />
                  </Column>
                  <Column className={"ml-3"}>
                    <Box
                      icon={<Text>&uarr;</Text>}
                      id={"Check Out"}
                      day={checkOutDay}
                      date={checkOutDate}
                      time={checkOutTime}
                    />
                  </Column>
                </Row>
              </Section>
            </SectionItem>

            <SectionItem>
              <SectionTitle title={"Price"} />
              <Section>
                <Row className={""}>
                  <Column>
                    <Price amount={paid} title={"Paid"} />
                  </Column>
                  <Column>
                    <Price amount={due} title={"Due"} />
                  </Column>
                  <Column>
                    <Price amount={total} title={"Total"} />
                  </Column>
                </Row>
              </Section>
            </SectionItem>

            <SectionItem>
              <SectionTitle title={"Frequently asked questions"} />
              {FAQS.map((faq) => (
                <Link
                  href={faq.link}
                  key={faq.title}
                  className={"text-gray-800 font-thin"}
                >
                  <Text className={"font-thin text-xl underline my-6"}>
                    {faq.title}
                  </Text>
                </Link>
              ))}
            </SectionItem>

            <SectionItem>
              <SectionTitle title={"Customer Support"} />
              <Support
                href={"mailto:ifemideestates@gmail.com"}
                title={"Phone Call"}
              />
              <Support href={"tel:+2349067921124"} title={"Contact the Host"} />
            </SectionItem>

            <Hr />

            <SectionItem>
              <Section>
                <Row>
                  <Column align={"left"}>
                    <Logo />
                  </Column>
                  <Column align={"right"}>
                    <Section>
                      <Row>
                        <Column align={"left"} className={"m-0 p-0"}>
                          <Link href={"https://wa.me/2349067921124"}>
                            <Img
                              height={18}
                              width={18}
                              className={"rounded-full overflow-hidden"}
                              src={
                                "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/2062095_application_chat_communication_logo_whatsapp_icon.svg/2048px-2062095_application_chat_communication_logo_whatsapp_icon.svg.png"
                              }
                            />
                          </Link>
                        </Column>
                        <Column align={"center"} className={"m-0 p-0"}>
                          <Link
                            href={"https://www.instagram.com/ifemideestates"}
                            className={"m-0 p-0"}
                            style={{ padding: 0, margin: 0 }}
                          >
                            <Img
                              height={18}
                              width={18}
                              className={"rounded-full overflow-hidden m-0 p-0"}
                              src={
                                "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png"
                              }
                            />
                          </Link>
                        </Column>
                        <Column align={"right"} className={"m-0 p-0"}>
                          <Link href={"https://www.x.com/ifemideestates"}>
                            <Img
                              height={18}
                              width={18}
                              className={"rounded-full overflow-hidden"}
                              src={
                                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7fj1vqat9XLO4cFwOG1uFqLXYDcISiYil2w&s"
                              }
                            />
                          </Link>
                        </Column>
                      </Row>
                    </Section>
                  </Column>
                </Row>
              </Section>

              <Container
                className={
                  "w-1/2 text-xs font-thin mt-3 flex flex-row justify-start p-0 m-0"
                }
              >
                <Text className={"m-0 p-0"}>
                  Ifemide Estates, Luxury Homes, Wellness Spa and Beauty Retreat
                </Text>
                <Text className={"m-0 p-0"}>
                  109/110 Sola Aladesuyi Street, Alagbaka GRA Akure
                </Text>
                <Text className={"m-0 p-0"}>Ondo State Nigeria</Text>
                <Container className={"mt-3"}>
                  <Text className={"m-0 p-0"}>
                    <Link href="tel:+2349067921124">+234 906 792 1124</Link>
                  </Text>
                  <Text className={"m-0 p-0"}>
                    <Link href="mailto:ifemideestates@gmail.com">
                      ifemideestates@gmail.com
                    </Link>
                  </Text>
                </Container>
              </Container>
            </SectionItem>
          </Container>
        </Body>
      </Tailwind>
    </Html>
  );
}

export default ConfirmedReservations;
