import React from 'react';
import Navigation from "./app/nav/Navigation";
import {BrowserRouter} from "react-router-dom";

function App() {
  return (
      <React.StrictMode>
          <BrowserRouter>
              <Navigation/>
          </BrowserRouter>
      </React.StrictMode>
  );
}

export default App;
