import React, { useEffect, useState } from "react";
import ListInput from "../../../components/ListInput";
import { getAvailableRooms } from "../../../api/rooms";
import { Room } from "../../../typings/rooms";
import { Customer } from "../../../typings/customer";
import { getCustomers } from "../../../api/customers";
import Calendar from "../../../components/Calendar";
import moment, { Moment } from "moment";
import NumberInput from "../../../components/NumberInput";
import { Reservation } from "../../../typings/reservation";
import EditCustomer from "../../customers/EditCustomer";
import { getReservations, saveReservation } from "../../../api/reservation";
import QuickPage from "../../../components/QuickPage";
import { render } from "@react-email/components";
import ConfirmedReservations from "../../../emails/ConfirmedReservations";
import { sendEmail } from "../../../api/email";
import Modal from "../../../components/Modal";

enum CalendarView {
  EMPTY,
  CHECK_IN,
  CHECK_OUT,
}

enum CustomerView {
  SELECT,
  EDIT_OR_ADD,
}

const DEFAULT_PAID = 180000;
const DEFAULT_TOTAL = 180000;
const DEFAULT_ADULTS = 4;
const DEFAULT_CHILDREN = 0;
const DEFAULT_CHECK_IN = "15:00";
const DEFAULT_CHECK_OUT = "12:00";
const DEFAULT_CALENDER_VIEW = CalendarView.EMPTY;
const DEFAULT_CUSTOMER_VIEW = CustomerView.SELECT;

const AddRoom = () => {
  const [rooms, setRooms] = useState<Room[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [showCalendarFor, setShowCalendarFor] = useState<CalendarView>(
    DEFAULT_CALENDER_VIEW,
  );

  const [customerView, setCustomerView] = useState(DEFAULT_CUSTOMER_VIEW);

  const [checkIn, setCheckIn] = useState<Moment | undefined>();
  const [checkOut, setCheckOut] = useState<Moment | undefined>();

  const [checkInTime, setCheckInTime] = useState(DEFAULT_CHECK_IN);
  const [checkOutTime, setCheckOutTime] = useState(DEFAULT_CHECK_OUT);

  const [customer, setCustomer] = useState<Customer | undefined>();

  const [room, setRoom] = useState<Room | undefined>();

  const [adults, setAdults] = useState(DEFAULT_ADULTS);
  const [children, setChildren] = useState(DEFAULT_CHILDREN);

  const [total, setTotal] = useState(DEFAULT_TOTAL);
  const [paid, setPaid] = useState(DEFAULT_PAID);

  const roomsMap = new Map(rooms.map((room) => [room.id, room]));
  const customersMap = new Map(
    customers.map((customer) => [customer.id, customer]),
  );

  function onCalendarChange(date: Moment): void {
    if (showCalendarFor === CalendarView.CHECK_IN) {
      if (checkOut && date.isAfter(checkOut)) {
        setCheckIn(checkOut);
        setCheckOut(date);
      } else setCheckIn(date);
    }

    if (showCalendarFor === CalendarView.CHECK_OUT) {
      if (checkIn && date.isBefore(checkIn)) {
        setCheckIn(date);
        setCheckOut(checkIn);
      } else setCheckOut(date);
    }

    setShowCalendarFor(CalendarView.EMPTY);
  }

  function handleRoomChange(id: string): void {
    if (roomsMap.has(id)) {
      setRoom(roomsMap.get(id) as Room);
    }
  }

  function validateTime(time: string): boolean {
    const format = "HH:mm";
    return moment(time, format, true).isValid();
  }

  function onCheckInTimeChange(time: string): void {
    setCheckInTime(time);
  }

  function onCheckOutTimeChange(time: string): void {
    setCheckOutTime(time);
  }

  function handleCustomerChange(id: string): void {
    if (customersMap.has(id)) setCustomer(customersMap.get(id) as Customer);
  }

  function formatNumber(num: number): string {
    return new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 10,
      notation: "compact",
      compactDisplay: "short",
    }).format(num);
  }

  function handlePaidChange(event: React.ChangeEvent<HTMLInputElement>) {
    isNumber(event) && setPaid(Number(event.target.value));
  }

  function handleTotalChange(event: React.ChangeEvent<HTMLInputElement>) {
    isNumber(event) && setTotal(Number(event.target.value));
  }

  function isNumber(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    return !isNaN(Number(value));
  }

  function reset(): void {
    setShowCalendarFor(CalendarView.EMPTY);
    setCheckIn(undefined);
    setCheckOut(undefined);
    setCustomer(undefined);
    setRoom(undefined);
    setAdults(DEFAULT_ADULTS);
    setChildren(DEFAULT_CHILDREN);
    setTotal(DEFAULT_TOTAL);
    setPaid(DEFAULT_PAID);
    setCustomerView(DEFAULT_CUSTOMER_VIEW);
    setShowCalendarFor(DEFAULT_CALENDER_VIEW);
  }

  async function sendMail(
    html: string,
    to: string,
    subject: string,
  ): Promise<void> {
    await sendEmail(html, to, subject)
      .then(() => {
        window.alert("Reservation completed!");
      })
      .catch(() => {
        window.alert("Could not complete reservation");
      });
  }

  async function reserve(): Promise<void> {
    if (isNaN(adults) || Number(adults) < 1) {
      window.alert("Invalid adults");
      return;
    }

    if (isNaN(children)) {
      window.alert("Invalid children");
      return;
    }

    if (!room) {
      window.alert("Invalid room");
      return;
    }

    if (!customer) {
      window.alert("Invalid customer");
      return;
    }

    if (!checkIn) {
      window.alert("Invalid check in");
      return;
    }

    if (!checkOut) {
      window.alert("Invalid check out");
      return;
    }

    if (!validateTime(checkInTime)) {
      window.alert("Invalid check in time");
      return;
    }

    if (!validateTime(checkOutTime)) {
      window.alert("Invalid check out time");
      return;
    }

    if (total <= 0) {
      window.alert("Invalid total value");
      return;
    }

    let reservationId = 1;

    const reservations: Reservation[] = await getReservations();

    if (reservations.length > 0) {
      reservationId = Number(reservations[0].reservationId) + 1;
    }

    const newReservation: Reservation = {
      checkInTime: moment(checkInTime, "HH:mm").format("HH:mm A"),
      checkOutTime: moment(checkOutTime, "HH:mm").format("HH:mm A"),
      specialRequests: "",
      updatedAt: moment().toISOString(),
      reservationId: reservationId,
      roomId: room!.id,
      customerId: customer!.id,
      checkInDate: checkIn!.toISOString(),
      checkOutDate: checkOut!.toISOString(),
      numberOfGuests: Number(adults) + Number(children),
      status: "Confirmed",
      tags: [{ customer: customer.title, rom: room.description }],
      payment: {
        paid,
        discount: checkOut.diff(checkIn, "days") * room.rate - total,
        deposit: 0,
        due: Math.max(0, total - paid),
        total,
        currency: "NGN",
      },
      createdAt: moment().toISOString(),
    };

    const html = await render(
      <ConfirmedReservations
        reservation={newReservation}
        room={room}
        customer={customer}
      />,
    );

    if (window.confirm(`Confirm new reservation with ID #${reservationId}`)) {
      await saveReservation(newReservation)
        .then(async () => {
          await sendMail(html, customer.emailAddress, "Booking Confirmation")
            .then(reset)
            .catch(() =>
              window.alert(
                "Failed to send email to the customer, but reservation has been saved to our database.",
              ),
            );
        })
        .catch(() =>
          window.alert("Failed to save reservation to our database."),
        );
    }
  }

  function renderModal() {
    return (
      <div className={"bg-white shadow border w-full h-full"}>
        <p>Me to you</p>
      </div>
    );
  }

  useEffect(() => {
    getAvailableRooms()
      .then(setRooms)
      .catch(() => {
        window.alert("Failed to get available rooms.");
      });
    getCustomers()
      .then(setCustomers)
      .catch(() => {
        window.alert("Failed to get customers.");
      });
  }, [customerView]);

  return (
    <div className={"p-6 w-full h-full"}>
      <QuickPage
        closeLink={"/"}
        title={"New Reservation"}
        right={"Reset"}
        rightOnClick={reset}
        component={
          <div>
            <div
              className={
                "rounded-2xl border p-6 flex flex-col mt-6 bg-white shadow-xl"
              }
            >
              <div className={"flex flex-row justify-between items-center"}>
                <div className={"flex flex-row my-2"}>
                  <p>Booking</p>
                </div>
              </div>
              <div className={""}>
                <ListInput
                  keySelector={"description"}
                  onChange={handleRoomChange}
                  icon={"bx bx-building-house"}
                  placeholder={room?.title || "Add room"}
                  selectedId={room?.id}
                  list={rooms}
                  showSearch={true}
                />
                <div className={"mt-2"} />
                <div className={"mt-2"} />
                <div
                  className={
                    "rounded-2xl border px-3 py-3 grid grid-cols-2 gap-4 mb-2"
                  }
                >
                  <div className={"flex flex-row items-center"}>
                    <i className="bx bx-log-out-circle text-2xl"></i>
                    <button
                      onClick={() =>
                        setShowCalendarFor(
                          showCalendarFor
                            ? CalendarView.EMPTY
                            : CalendarView.CHECK_IN,
                        )
                      }
                      className={`h-full mx-3 items-center ${checkIn ? "" : "text-gray-500"}`}
                    >
                      {checkIn ? checkIn.format("MMM D 'YY") : "Check In"}
                    </button>
                  </div>
                  <div className={"flex flex-row items-center"}>
                    <i className="bx bx-log-in-circle text-2xl"></i>
                    <button
                      onClick={() =>
                        setShowCalendarFor(
                          showCalendarFor
                            ? CalendarView.EMPTY
                            : CalendarView.CHECK_OUT,
                        )
                      }
                      className={`h-full mx-3 items-center ${checkOut ? "" : "text-gray-500"}`}
                    >
                      {checkOut ? checkOut.format("MMM D 'YY") : "Check Out"}
                    </button>
                  </div>
                </div>
                <div
                  className={
                    "rounded-2xl border px-3 py-3 grid grid-cols-2 gap-4"
                  }
                >
                  <NumberInput
                    onChange={onCheckInTimeChange}
                    icon={"bx bxs-timer"}
                    placeholder={"HH:mm"}
                    defaultValue={checkInTime}
                    valuePrefix={""}
                  />
                  <NumberInput
                    onChange={onCheckOutTimeChange}
                    icon={"bx bx-timer"}
                    placeholder={"HH:mm"}
                    defaultValue={checkOutTime}
                    valuePrefix={""}
                  />
                </div>
              </div>
              {showCalendarFor !== CalendarView.EMPTY && (
                <div className={"w-full mt-3"}>
                  <Calendar
                    onChange={onCalendarChange}
                    checkIn={checkIn}
                    checkOut={checkOut}
                  />
                </div>
              )}
            </div>

            <div
              className={
                "rounded-2xl border p-6 flex flex-col mt-6 bg-white shadow-xl"
              }
            >
              <div
                className={"flex flex-row justify-between items-center my-2"}
              >
                <div className={"flex flex-row"}>
                  <p
                    onClick={() => setCustomerView(CustomerView.SELECT)}
                    className={`${customerView === CustomerView.SELECT ? "" : "text-gray-500"}`}
                  >
                    Guests
                  </p>
                  <p className={"mx-2"}>&middot;</p>
                  <p
                    className={`${customerView === CustomerView.EDIT_OR_ADD ? "" : "text-gray-500"}`}
                    onClick={() => setCustomerView(CustomerView.EDIT_OR_ADD)}
                  >
                    Customers
                  </p>
                </div>
                <div className={"flex flex-row items-center"}>
                  <i className="bx bxs-group text-2xl"></i>
                  <p>{`${Number(adults) + Number(children)}`}</p>
                </div>
              </div>
              {customerView === CustomerView.SELECT ? (
                <ListInput
                  onChange={handleCustomerChange}
                  keySelector={"title"}
                  showSearch={true}
                  icon={"bx bx-body"}
                  placeholder={"Customer"}
                  selectedId={customer?.id}
                  list={customers}
                />
              ) : (
                <EditCustomer />
              )}
              {customerView === CustomerView.SELECT && (
                <div
                  className={
                    "rounded-2xl border px-3 py-3 grid grid-cols-2 gap-4 mt-3"
                  }
                >
                  <NumberInput
                    defaultValue={`${adults}`}
                    onChange={setAdults}
                    icon={"bx bx-body"}
                    placeholder={"Adults"}
                    valuePrefix={"Adults "}
                  />
                  <NumberInput
                    defaultValue={`${children}`}
                    onChange={setChildren}
                    icon={"bx bxs-baby-carriage"}
                    placeholder={"Children"}
                    valuePrefix={"Children"}
                  />
                </div>
              )}
            </div>

            <div className={"mt-6"}>
              <div className={"mb-3"}>
                <p className={"font-extrabold text-xl"}>Costs and discounts</p>
                <p className={"text-gray-400"}>Applying discounts?</p>
              </div>
              <div className={"grid grid-cols-2 gap-8"}>
                <div
                  className={
                    "text-center rounded-2xl border py-6 bg-white shadow-xl"
                  }
                >
                  <p className={"text-gray-500 mb-2"}>Paid</p>
                  <input
                    onChange={handlePaidChange}
                    value={paid}
                    type={"number"}
                    className={"w-full font-extrabold text-xl text-center"}
                  />
                  <p className={"text-xs text-gray-500 italic"}>
                    {formatNumber(paid)}
                  </p>
                </div>
                <div
                  className={
                    "text-center rounded-2xl border py-6 bg-white shadow-xl"
                  }
                >
                  <p className={"text-gray-500 mb-2"}>Total</p>
                  <input
                    onChange={handleTotalChange}
                    value={total}
                    type={"number"}
                    className={"w-full font-extrabold text-xl text-center"}
                  />
                  <p className={"text-xs text-gray-500 italic"}>
                    {formatNumber(total)}
                  </p>
                </div>
              </div>
            </div>

            <div className={"flex items-center justify-center w-full mt-6 "}>
              <button
                onClick={reserve}
                className={
                  "shadow-2xl bg-black text-white rounded-full m-3 p-4 px-12 w-full font-extrabold"
                }
              >
                {`Reserve for ${formatNumber(total)}`}
              </button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default AddRoom;
