import React from "react";
import { Link } from "react-router-dom";

interface Grid {
  id: string;
  icon: string;
  title: string;
  description: string;
  disabled?: boolean;
  new?: boolean;
  onClick: (id: string) => void;
}

const GridItem = (props: Grid) => {
  return (
    <div className={"relative"}>
      <button
        disabled={props.disabled}
        className={`w-full h-40 rounded-xl shadow-xl border bg-white flex flex-col justify-center p-3 text-left ${props.disabled ? "bg-gray-200" : ""}`}
      >
        <p className={"font-thin text-2xl text-gray-500 mb-"}>{props.title}</p>
        <p className={"text-xs text-black"}>{props.description}</p>
      </button>
      {props.new && (
        <p
          className={
            "absolute top-2 right-2 bg-pink-400 px-3 py-1 text-xs text-white rounded-full"
          }
        >
          NEW
        </p>
      )}
    </div>
  );
};

const HostHome = () => {
  return (
    <div
      className={"w-full h-full flex flex-col justify-center items-center px-8"}
    >
      <p className={"text-5xl font-thin mb-12 w-4/5 text-center"}>
        What would you like to do?
      </p>
      <div className={"w-full space-y-4"}>
        <div className="w-full grid grid-cols-2 gap-4">
          <Link to={"/customers"}>
            <GridItem
              new={true}
              id={"1"}
              onClick={() => {}}
              icon={""}
              title={"Manage Customers"}
              description={
                "Modify or create a new customer or enquirer and save to the database."
              }
            />
          </Link>
          <Link to={"/reservations"}>
            <GridItem
              new={true}
              id={"1"}
              onClick={() => {}}
              icon={""}
              title={"Create Reservation"}
              description={
                "Create a new reservation and send automatic confirmation email."
              }
            />
          </Link>
        </div>
        <div className="w-full grid grid-cols-2 gap-4">
          <GridItem
            disabled={true}
            id={"1"}
            onClick={() => {}}
            icon={""}
            title={"Energy Documentation"}
            description={
              "Document energy usages such as petrol, diesel or gas used on site."
            }
          />
          <GridItem
            disabled={true}
            id={"1"}
            onClick={() => {}}
            icon={""}
            title={"Stock Management"}
            description={
              "Modify or create a new customer or enquirer to the database"
            }
          />
        </div>
        <div className="w-full grid grid-cols-2 gap-4">
          <GridItem
            disabled={true}
            id={"1"}
            onClick={() => {}}
            icon={""}
            title={"Customer Invoice"}
            description={
              "Generate customer invoice from completed or new reservations."
            }
          />
        </div>
      </div>
    </div>
  );
};

export default HostHome;
