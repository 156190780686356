import React, { useEffect, useMemo, useState } from "react";
import { Customer } from "../../typings/customer";
import { getCustomers, saveCustomers } from "../../api/customers";
import moment from "moment";
import { v4 as UuidV4 } from "uuid";
import ListInput from "../../components/ListInput";
import FormEntry from "../../components/FormEntry";
import Button from "../../components/Button";

interface Props {
  customerId?: string;
}

const EditCustomer = (props: Props) => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [customer, setCustomer] = useState<Customer | undefined>();

  const [salutation, setSalutation] = useState(customer?.salutation || "");
  const [firstName, setFirstName] = useState(customer?.firstName || "");
  const [lastName, setLastName] = useState(customer?.lastName || "");
  const [phoneLine1, setPhoneLine1] = useState(
    customer?.phoneNumberLine1 || "",
  );
  const [phoneLine2, setPhoneLine2] = useState(
    customer?.phoneNumberLine2 || "",
  );
  const [emailAddress, setEmailAddress] = useState(
    customer?.emailAddress || "",
  );
  const [addressLine1, setAddressLine1] = useState(
    customer?.addressLine1 || "",
  );
  const [addressLine2, setAddressLine2] = useState(
    customer?.addressLine2 || "",
  );

  const [working, setWorking] = useState(true);

  const customersMapById = useMemo(() => {
    return new Map(customers.map((customer) => [customer.id, customer]));
  }, [customers]);

  function handleCancel() {
    if (working) {
      window.alert("Loading...");
      return;
    }
    setCustomer(undefined);
    renovate();
  }

  function isValidEmail(email: string) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  function isValidPhoneNumber(phoneNumber: string) {
    const regex = /^\+?[1-9]\d{1,14}$/;
    return regex.test(phoneNumber);
  }

  function isValidName(name: string) {
    const regex = /^[\p{L}\s'-]{2,50}$/u;
    return regex.test(name);
  }

  async function handleSave() {
    if (working) {
      window.alert("Loading...");
      return;
    }

    if (!salutation) {
      window.alert("A salutation is required.");
      return;
    }

    if (!isValidName(firstName)) {
      window.alert("A valid first name is required.");
      return;
    }

    if (!isValidName(lastName)) {
      window.alert("A valid first name is required.");
      return;
    }

    if (!isValidEmail(emailAddress)) {
      window.alert("A valid email is required.");
      return;
    }

    if (!isValidPhoneNumber(phoneLine1)) {
      window.alert("A valid phone number line 1 is required.");
      return;
    }

    if (phoneLine2.trim() !== "" && !isValidPhoneNumber(phoneLine2)) {
      window.alert("A valid phone number line 2 is required.");
      return;
    }

    if (!addressLine1) {
      window.alert("A valid address line 1 is required.");
      return;
    }

    if (!addressLine2) {
      window.alert("A valid address line 2 is required.");
      return;
    }

    setWorking(true);

    const now = moment();

    let customerData: Customer = {
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      createdAt: now.toISOString(),
      emailAddress: emailAddress,
      firstName: firstName,
      id: UuidV4(),
      lastName: lastName,
      phoneNumberLine1: phoneLine1,
      phoneNumberLine2: phoneLine2,
      salutation: salutation,
      thumbnailUrl: "",
      title: `${salutation} ${firstName} ${lastName}`,
      updatedAt: now.toISOString(),
    };

    await getCustomers()
      .then(async (data) => {
        let list: Customer[] = [];

        const freshCustomersEmailMap = new Map(
          data.map((customer) => [customer.emailAddress, customer]),
        );

        if (!customer && freshCustomersEmailMap.has(customerData.emailAddress))
          window.alert(
            "Customer with the same email address exists in the database.",
          );
        else {
          if (customer) {
            const freshCustomersIdMap = new Map(
              data.map((customer) => [customer.id, customer]),
            );

            customerData.id = customer.id;
            freshCustomersIdMap.set(customer.id, { ...customerData });
            list = [...Array.from(freshCustomersIdMap.values())];
          } else {
            list = [...data, customerData];
          }

          list.sort(
            (a, b) =>
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
          );

          await saveCustomers(list)
            .then(() => {
              setCustomers(list);
              setCustomer(customerData);
              window.alert("Customer data saved!");
            })
            .catch(() => {
              window.alert(
                "Could not complete action, please try again later.",
              );
            });
        }
      })
      .catch(() => {
        window.alert("Could not complete action, please try again later.");
      });

    setWorking(false);
  }

  function handleCustomerChange(id: string) {
    if (customersMapById.has(id)) setCustomer(customersMapById.get(id));
  }

  function renovate(customer?: Customer) {
    setSalutation(customer?.salutation || "");
    setFirstName(customer?.firstName || "");
    setLastName(customer?.lastName || "");
    setEmailAddress(customer?.emailAddress || "");
    setPhoneLine1(customer?.phoneNumberLine1 || "");
    setPhoneLine2(customer?.phoneNumberLine2 || "");
    setAddressLine1(customer?.addressLine1 || "");
    setAddressLine2(customer?.addressLine2 || "");
  }

  useEffect(() => {
    setWorking(true);
    getCustomers().then(setCustomers);
  }, []);

  useEffect(() => {
    if (props.customerId && customersMapById.has(props.customerId)) {
      const newVar = customersMapById.get(props.customerId) as Customer;
      setCustomer(newVar);
      renovate(newVar);
    }
    setWorking(false);
  }, [customer, customersMapById, props.customerId]);

  useEffect(() => {
    renovate(customer);
  }, [customer]);

  return (
    <div className={"flex flex-col"}>
      <ListInput
        onChange={handleCustomerChange}
        keySelector={"title"}
        showSearch={true}
        icon={"bx bx-body"}
        placeholder={"Select existing customers to edit"}
        selectedId={customer?.id}
        list={customers}
      />
      <FormEntry
        placeholder={"Captain"}
        title={"Salutation*"}
        value={salutation}
        type={"text"}
        defaultValue={salutation}
        onChange={setSalutation}
      />
      <FormEntry
        placeholder={"Demurin Peter"}
        title={"First names*"}
        value={firstName}
        type={"name"}
        defaultValue={firstName}
        onChange={setFirstName}
      />
      <FormEntry
        placeholder={"Bucknor"}
        title={"Last name*"}
        value={lastName}
        type={"name"}
        defaultValue={lastName}
        onChange={setLastName}
      />
      <FormEntry
        title={"Email address*"}
        placeholder={"captaindemurinbucknor@gmail.com"}
        value={emailAddress}
        defaultValue={emailAddress}
        type={"email"}
        onChange={setEmailAddress}
      />
      <FormEntry
        title={"Phone line 1*"}
        placeholder={"+23407061047490"}
        value={phoneLine1}
        type={"phone"}
        defaultValue={phoneLine1}
        onChange={setPhoneLine1}
      />
      <FormEntry
        title={"Phone line 2"}
        placeholder={"+23407061047490"}
        value={phoneLine2}
        type={"phone"}
        defaultValue={phoneLine2}
        onChange={setPhoneLine2}
      />
      <FormEntry
        title={"Address line 1*"}
        placeholder={"109, Parkview Street"}
        value={addressLine1}
        defaultValue={addressLine1}
        type={"address"}
        onChange={setAddressLine1}
      />
      <FormEntry
        title={"Address line 2*"}
        placeholder={"Ikoyi, Lagos Nigeria"}
        value={addressLine2}
        defaultValue={addressLine2}
        type={"postcode"}
        onChange={setAddressLine2}
      />
      <div className={"flex flex-row justify-end space-x-3"}>
        <Button onClick={handleSave} type={"primary"} text={"Save"} />
        <Button onClick={handleCancel} type={"secondary"} text={"Clear"} />
      </div>
    </div>
  );
};

export default EditCustomer;
