import React from "react";
import { Route, Routes } from "react-router-dom";
import AddRoom from "../pages/reservations/rooms/AddRoom";
import Authenticator from "../components/Authenticator";
import HostHome from "../pages/home/HostHome";
import Customers from "../pages/customers/Customers";

const Navigation = () => {
  return (
    <div className={"w-screen h-screen flex flex-row bg-gray-200"}>
      <div className={"flex w-full mx-auto max-w-[465px]"}>
        <Routes>
          <Route
            path="/"
            element={
              <Authenticator>
                <HostHome />
              </Authenticator>
            }
          />
          <Route
            path="/reservations"
            element={
              <Authenticator>
                <AddRoom />
              </Authenticator>
            }
          />
          <Route
            path="/customers"
            element={
              <Authenticator>
                <Customers />
              </Authenticator>
            }
          />
          <Route
            path="*"
            element={
              <Authenticator>
                <HostHome />
              </Authenticator>
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default Navigation;
