import axios from "axios";

export const BASE_URL = process.env.REACT_APP_BASE_URL || "";

export async function get(fileName: string) {
  console.log("Base URL:", BASE_URL);
  return new Promise(async (resolve, reject) => {
    axios
      .get(`${BASE_URL}/api/${fileName}`)
      .then((r) => {
        if (r.status === 200) resolve(r.data);
        else reject();
      })
      .catch(reject);
  });
}

export async function patch(fileName: string, body: any) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${BASE_URL}/api/${fileName}`, body)
      .then((r) => {
        if (r.status === 200) resolve(r.data);
        else reject();
      })
      .catch(reject);
  });
}
