import React from "react";

const Button = ({
  onClick,
  type,
  text,
}: {
  onClick: () => void;
  type: "primary" | "secondary";
  text: string;
}) => {
  const styles = { primary: "bg-pink-500 text-white", secondary: "border" };
  return (
    <button
      onClick={onClick}
      className={`font-semibold shadow-xl rounded-xl p-2 px-6 ${styles[type]}`}
    >
      {text}
    </button>
  );
};

export default Button;
