import React from "react";
import QuickPage from "../../components/QuickPage";
import EditCustomer from "./EditCustomer";

const Customers = () => {
  return (
    <div className={"bg-white w-full h-full p-6 mb-12"}>
      <QuickPage
        closeLink={"/"}
        title={"Manage Customers"}
        component={<EditCustomer />}
        right={"        "}
        rightOnClick={() => {}}
      />
    </div>
  );
};

export default Customers;
