import { Reservation } from "../typings/reservation";
import { get, patch } from "./database";

const FILE_NAME = "reservations";

export const getReservations = (): Promise<Reservation[]> => {
  return new Promise((resolve, reject) => {
    get(FILE_NAME)
      .then((r) => resolve(r as Reservation[]))
      .catch(reject);
  });
};

export const saveReservation = (item: Reservation): Promise<Reservation> => {
  return new Promise(async (resolve, reject) => {
    await getReservations()
      .then(async (reservations) => {
        reservations.unshift(item);
        await patch(FILE_NAME, reservations)
          .then(() => resolve(item))
          .catch(reject);
      })
      .catch(reject);
  });
};
