import React from "react";

const QuickPage = ({
  right,
  rightOnClick,
  component,
  title,
  closeLink,
}: {
  title: string;
  right?: string;
  rightOnClick?: () => void;
  component: any;
  closeLink: string;
}) => {
  return (
    <div className={"w-full bg-transparent"}>
      <div className={"flex flex-row items-center justify-between mb-6"}>
        <a href={closeLink}>
          <i className="bx bx-x text-4xl"></i>
        </a>
        <p className={"font-extrabold"}>{title}</p>
        {right && <button onClick={rightOnClick}>{right}</button>}
      </div>
      {component}
    </div>
  );
};

export default QuickPage;
