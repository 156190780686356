import { BASE_URL } from "./database";
import axios from "axios";

export const sendEmail = (html: string, to: string, subject: string) => {
  return new Promise(async (resolve, reject) => {
    axios
      .post(`${BASE_URL}/api/email?to=${to}&subject=${subject}`, html, {
        headers: { "Content-Type": "text/html" },
      })
      .then((r) => {
        if (r.status === 200) resolve(r.data);
        else reject();
      })
      .catch(reject);
  });
};
