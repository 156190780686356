import React, { useCallback, useEffect, useState } from "react";
import Calendar from "./Calendar";

interface Item {
  id: string;
  title: string;
  thumbnailUrl: string;
}

interface Props {
  showSearch?: boolean;
  placeholder?: string;
  selectedId?: string;
  isCalendar?: boolean;
  onChange?: (id: string) => void;
  keySelector: string;
  icon: string;
  list: Item[];
}

const ListInput = (props: Props) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [itemSelected, setItemSelected] = useState<Item | null>(null);
  const [filterItems, setFilterItems] = useState<Item[]>(props.list);
  const [search, setSearch] = useState("");

  const toggleDropDown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    searchList(event.target.value);
  };

  const searchList = useCallback(
    (value: string) => {
      if (value.trim() === "") {
        setFilterItems(props.list);
      } else {
        const filtered = props.list.filter((item: Item) =>
          item.title.toLowerCase().includes(value.toLowerCase()),
        );
        setFilterItems(filtered);
      }
    },
    [props.list],
  );

  const setItem = (item: Item) => {
    props.onChange && props.onChange(item.id);
    toggleDropDown();
    setItemSelected(item);
    setSearch("");
  };

  const showContent = () => {
    if (props.isCalendar) return <Calendar />;

    return (
      <ul className="absolute top-full left-0 right-0 z-50 mt-2 p-3 shadow-2xl rounded-xl border max-h-80 overflow-auto bg-white">
        {props.showSearch && (
          <input
            value={search}
            onChange={handleSearchChange}
            className="border rounded-xl p-2 w-full"
            placeholder="Search"
          />
        )}
        {filterItems.map((item) => (
          <p key={item.id} onClick={() => setItem(item)} className="p-2">
            {item.title}
          </p>
        ))}
        {filterItems.length === 0 ? (
          <p className={"mt-3 text-gray-500"}>No records to show</p>
        ) : null}
      </ul>
    );
  };

  useEffect(() => {
    for (let item of props.list) {
      if (item.id === props.selectedId) {
        setItemSelected(item);
      }
    }
    searchList(search);
  }, [props.list, props.selectedId, search, searchList]);

  useEffect(() => {
    if (!props.selectedId) setItemSelected(null);
  }, [props.selectedId]);

  return (
    <div className="rounded-2xl border flex flex-row flex-wrap py-3 px-2 items-center w-full relative">
      <i className={`${props.icon} text-2xl`}></i>
      <input
        onClick={toggleDropDown}
        className={`h-full mx-3 flex-grow bg-transparent focus:outline-none ${itemSelected ? "" : "text-gray-500"}`}
        placeholder={props.placeholder}
        readOnly
        defaultValue={itemSelected?.title}
      />
      {isDropdownVisible && showContent()}
    </div>
  );
};

export default ListInput;
